<template>
  <painel-component :itens="itensOne">
    <div class="filter-position" v-if="btnFilter">
      <button-one class="btn-filter" icon="filter" @click="filterWindow" />
    </div>

    <!-- MODAL ATUALIZAR STATUS -->
    <div class="modal-window" v-if="openModalUpdateStatus">
      <div class="container-card camp-schedule">
        <div role="button" class="btn-close" data-v-3c7c0b1c="" @click="closeModal"></div>

        <!-- Cadastro -->
        <div v-if="status !== '1'" class="d-flex align-items-center p-3 position-relative card-status mt-4">
          <div class="ms-3 w-75">
            <p class="mb-1 text-truncate status-name">Cadastro</p>
          </div>
          <div class="position-absolute end-0 me-1 me-lg-3">
            <button tooltip="Cadastro" type="button" class="button-send-status" @click="updateStatusContract(1)">
              <i class="send icon"></i>
            </button>
          </div>
        </div>

        <!-- Enviado para cliente -->
        <div
          v-if="status !== '2'"
          class="d-flex align-items-center bg-body-secondary p-3 position-relative card-status mt-4"
        >
          <div class="ms-3 w-75">
            <p class="mb-1 text-truncate status-name">Enviado para cliente</p>
          </div>
          <div class="position-absolute end-0 me-1 me-lg-3">
            <button
              tooltip="Enviar para cliente"
              type="button"
              class="button-send-status"
              @click="updateStatusContract(2)"
            >
              <i class="send icon"></i>
            </button>
          </div>
        </div>

        <!-- Assinado -->
        <div
          v-if="status !== '3'"
          class="d-flex align-items-center bg-body-secondary p-3 position-relative card-status mt-4"
        >
          <div class="ms-3 w-75">
            <p class="mb-1 text-truncate status-name">Assinado</p>
          </div>
          <div class="position-absolute end-0 me-1 me-lg-3">
            <button tooltip="Assinado" type="button" class="button-send-status" @click="updateStatusContract(3)">
              <i class="send icon"></i>
            </button>
          </div>
        </div>

        <!-- Cancelado -->
        <div
          v-if="status !== '4'"
          class="d-flex align-items-center bg-body-secondary p-3 position-relative card-status mt-4"
        >
          <div class="ms-3 w-75">
            <p class="mb-1 text-truncate status-name">Cancelado</p>
          </div>
          <div class="position-absolute end-0 me-1 me-lg-3">
            <button tooltip="Cancelado" type="button" class="button-send-status" @click="updateStatusContract(4)">
              <i class="send icon"></i>
            </button>
          </div>
        </div>

        <div
          v-if="status !== '5'"
          class="d-flex align-items-center bg-body-secondary p-3 position-relative card-status mt-4"
        >
          <div class="ms-3 w-75">
            <p class="mb-1 text-truncate status-name">Finalizado</p>
          </div>
          <div class="position-absolute end-0 me-1 me-lg-3">
            <button
              tooltip="Enviar para cliente"
              type="button"
              class="button-send-status"
              @click="updateStatusContract(5)"
            >
              <i class="send icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <form class="mb-4 mt-5">
      <transition name="fade-in-top">
        <row-component v-if="filter">
          <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
            <label>Código:</label>
            <input-component :type-input="'text'" v-model="formDate.code" />
          </row-col-component>

          <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
            <label>Nome:</label>
            <input-component :type-input="'text'" v-model="formDate.name" />
          </row-col-component>

          <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
            <label>Status:</label>
            <multiselect
              v-model="formDate.status"
              :searchable="true"
              :options="selectStatusContract"
              :noResultsText="noResultsText"
              :noOptionsText="noResultsText"
            />
          </row-col-component>

          <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
            <button-one class="mx-1" @submit="submit" :icon="'search'" />
            <button-float class="mx-1" :itens="itensTwo" v-if="buttonCheck.create" />
          </row-col-component>
        </row-component>
      </transition>
    </form>

    <loder-send-component :text-title="'Carregando'" v-if="showLoader" />

    <div v-else>
      <row-component>
        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end'">
          <div class="toggle_preferences">
            <button
              :class="{ 'bg-active': parseInt(cardTable) === 1 }"
              @click="toggleCard"
              type="button"
              tooltip="Card"
            >
              <i class="window maximize outline icon"></i>
            </button>
            <button
              :class="{ 'bg-active': parseInt(cardTable) === 2 }"
              @click="toggleTable"
              type="button"
              tooltip="Tabela"
            >
              <i class="align justify icon"></i>
            </button>
          </div>
        </row-col-component>
      </row-component>

      <row-component v-if="parseInt(cardTable) === 1">
        <row-col-component
          :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'"
          v-for="(data, index) in objectReturn.itens.data"
          :key="index"
        >
          <div class="card-container">
            <div class="flag-status" :style="{ backgroundColor: getStatusStyle(data.status).backgroundColor }">
              <i :class="getStatusStyle(data.status).icon"></i>
              {{ statusStringContract(data.status) }}
            </div>

            <p>Código: {{ data.code }}</p>

            <img v-if="data.client_photo" :src="data.client_photo" alt="" />
            <img v-else src="@/assets/img/avatar.png" alt="" />
            <p>
              <span> {{ data.client_name }} </span>
            </p>

            <p>
              <strong>{{ data.name }}</strong>
            </p>

            <div class="responsible-task">
              <h6>Criador:</h6>
              <img v-if="data.photo_creator" :src="data.photo_creator" class="avatar__image_2" alt="" />
              <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="" />
              {{ data.creator_user_name }}
            </div>

            <div class="buttons">
              <button tooltip="Editar" class="primary eye" v-if="buttonCheck.edit" @click="routeEdit(data.id)">
                <i class="edit icon"></i>
              </button>
              <button tooltip="Atualizar Status" class="primary eye" @click="modalUpdateStatus(data.id, data.status)">
                <i class="send icon"></i>
              </button>
            </div>
          </div>
        </row-col-component>
      </row-component>

      <table class="table table-striped" v-if="parseInt(cardTable) === 2">
        <thead>
          <tr>
            <th scope="col" style="width: 10%">Código</th>
            <th scope="col" style="width: 20%">Nome</th>
            <th scope="col" style="width: 20%">Criador</th>
            <th scope="col" style="width: 20%">Status</th>
            <th scope="col" style="width: 10%" v-if="buttonCheck.edit">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in objectReturn.itens.data" :key="index">
            <td data-label="Código">{{ data.code }}</td>
            <td data-label="Nome">{{ data.client_name }}</td>
            <td data-label="Criador">
              <img v-if="data.photo_creator" :src="data.photo_creator" class="avatar__image_2" alt="" />
              <img v-else src="@/assets/img/avatar.png" class="avatar__image_2" alt="" />
              {{ data.creator_user_name }}
            </td>
            <td data-label="Status">{{ statusStringContract(data.status) }}</td>
            <td data-label="Ações" v-if="buttonCheck.edit">
              <button
                tooltip="Editar"
                class="btn btn-success button-show mx-1"
                v-if="buttonCheck.edit"
                @click="routeEdit(data.id)"
              >
                <i class="edit icon"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <button-pagination :pagination="objectReturn.itens" :offset="3" @paginate="loadContracts" />
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonFloat from '@/components/buttons/ButtonFloat';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import InputComponent from '@/components/form/InputComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import LoderSendComponent from '@/components/LoderSendComponent';
import Multiselect from '@vueform/multiselect';

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'ContractSearch',
  components: {
    PainelComponent,
    ButtonOne,
    ButtonFloat,
    ButtonPagination,
    InputComponent,
    RowComponent,
    RowColComponent,
    LoderSendComponent,
    Multiselect
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Contrato');
    this.$store.dispatch('loadClientListSelect');
    this.loadContracts(1);
    this.resizeWindow();
    this.checkACL();
  },

  data() {
    return {
      itensOne: {
        title: 'Pesquisa',
        icon: 'list alternate'
      },

      itensTwo: {
        route: '/contract/create',
        icon: 'plus',
        name: ''
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false
      },

      formDate: {
        code: '',
        name: '',
        status: ''
      },

      cardTable: '',
      preferences: {
        type: 3,
        preference: ''
      },

      filter: true,
      btnFilter: false,

      showLoader: true,
      searchSubmit: false,
      openModalUpdateStatus: false
    };
  },

  methods: {
    submit() {
      this.loadContracts(1);
      this.searchSubmit = true;
    },

    routeEdit(id) {
      this.$router.push({ name: 'contract-edit', params: { id: id } });
    },

    filterWindow() {
      this.filter = !this.filter;
    },

    resizeWindow() {
      if (window.innerWidth <= 991) {
        this.filter = false;
        this.btnFilter = true;
      } else {
        this.filter = true;
        this.btnFilter = false;
      }
    },

    toggleTable() {
      this.userPreferences(2);
      this.cardTable = 2;
    },

    toggleCard() {
      this.userPreferences(1);
      this.cardTable = 1;
    },

    userPreferences(preference) {
      this.preferences.preference = preference;
      this.$store.dispatch('userPreferences', this.preferences);
    },

    loadContracts(page) {
      this.showLoader = true;
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadContracts', { ...this.params, page })
          .then(r => {
            this.cardTable = r.tableCard;
            this.showLoader = false;
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.itens.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 10000 });
              }
            }
          })
          .catch(() => {
            this.showLoader = false;
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 28)
        .then(r => {
          if (r.data.indexOf('contract-search') !== -1) {
            this.showLoader = false;

            if (r.data.indexOf('contract-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('contract-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('contract-create') !== -1) {
              this.buttonCheck.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusStringContract(status) {
      if (parseInt(status) === 1) {
        return 'Cadastro';
      }
      if (parseInt(status) === 2) {
        return 'Enviado para cliente';
      }
      if (parseInt(status) === 3) {
        return 'Assinado';
      }
      if (parseInt(status) === 4) {
        return 'Cancelado';
      }
      if (parseInt(status) === 5) {
        return 'Finalizado';
      }
    },

    getStatusStyle(status) {
      const statusNumber = parseInt(status);
      if (statusNumber === 1) {
        return { backgroundColor: '#5a5a5a', icon: 'edit outline icon' };
      }
      if (statusNumber === 2) {
        return { backgroundColor: '#2185d0', icon: 'send icon' };
      }
      if (statusNumber === 3) {
        return { backgroundColor: '#16ab39', icon: 'check circle outline icon' };
      }
      if (statusNumber === 4) {
        return { backgroundColor: '#db2828', icon: 'close icon' };
      }
      if (statusNumber === 5) {
        return { backgroundColor: '#b5cc18', icon: 'check circle icon' };
      }
      return { backgroundColor: '#000', icon: '' };
    },

    modalUpdateStatus(id, status) {
      if (status === '4') {
        toast.info('O status está Cancelado e não pode ser alterado!', { timeout: 10000 });
        return; // Impede que o modal seja aberto
      }

      this.id = id;
      this.status = status;
      this.openModalUpdateStatus = true;
    },

    closeModal() {
      this.openModalUpdateStatus = false;
      this.id = null;
    },

    updateStatusContract(status) {
      if (!this.searchSubmit && this.id) {
        this.$store
          .dispatch('updateStatusContract', { id: this.id, status })
          .then(response => {
            this.loadContracts(1);
            this.closeModal();
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (response.itens.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 10000 });
              }
            }
          })
          .catch(() => {
            this.showLoader = false;
            this.searchSubmit = false;
          });
      }
    }
  },

  computed: {
    noResultsText() {
      return this.$store.state.var.selectNotSearch;
    },

    selectInput() {
      return this.$store.state.var.selectActiveInactive;
    },

    selectClientList() {
      return this.$store.state.var.selectClientList;
    },

    selectStatusContract() {
      return this.$store.state.var.selectStatusContract;
    },

    objectReturn() {
      return this.$store.state.contract.items;
    },

    params() {
      return {
        page: this.objectReturn.current_page,
        code: this.formDate.code,
        name: this.formDate.name,
        creator_user_id: this.formDate.creator_user_id,
        status: this.formDate.status
      };
    }
  }
};
</script>

<style scoped src="@/assets/css/table-resp.css"></style>
<style scoped>
.toggle_preferences > button {
  border: 0;
  width: 45px;
  height: 45px;
  color: #fff;
  margin: 0 5px;
  padding-right: 4px;
  border-radius: 50%;
  background-color: #323232;
}

.button-show {
  width: 35px;
  height: 35px;
  font-size: 15px;
  border-radius: 50px;
  padding-left: 0.5rem;
}

.bg-active {
  background-color: #d67c1c !important;
}

.flag-status {
  top: 0;
  color: #fff;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  position: absolute;
  font-family: 'Comfortaa', sans-serif;
}

.responsible-task .avatar__image_2 {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #d67c1c;
}

.card-container {
  width: 350px;
  display: flex;
  margin: 20px 0;
  color: #2f3032;
  max-width: 100%;
  overflow: hidden;
  min-height: 445px;
  padding-top: 50px;
  position: relative;
  text-align: center;
  padding-bottom: 0;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1px #ededed;
  justify-content: space-between;
  box-shadow: 10px 10px 15px rgb(0 0 0 / 35%);
}

.card-container > img {
  width: 90px;
  padding: 7px;
  height: 90px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #d67c1c;
}

.buttons {
  display: flex;
  min-height: 55px;
  border-top: 1px solid #ededed;
}

button.primary {
  width: 100%;
  height: 60px;
  color: #7e7e7e;
  border: 1px solid #fdfdfd;
  background-color: #f5f5f5;
}

.responsible-task {
  padding: 15px;
  min-height: 80px;
  text-align: left;
  border-top: 1px solid #ededed;
}

.responsible-task h6 {
  font-size: 14px;
}

.flag-status {
  top: 0;
  width: 100%;
  color: #ededed;
  font-size: 14px;
  text-align: left;
  padding: 10px 20px;
  position: absolute;
  font-family: 'Comfortaa', sans-serif;
}

.toggle_preferences > button {
  border: 0;
  width: 45px;
  height: 45px;
  color: #fff;
  margin: 0 5px;
  padding-right: 4px;
  border-radius: 50%;
  background-color: #323232;
}

.button-show {
  width: 35px;
  height: 35px;
  font-size: 15px;
  border-radius: 50px;
  padding-left: 0.5rem;
}

.bg-active {
  background-color: #d67c1c !important;
}

.modal-window {
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  place-items: center;
  background-color: rgb(0, 0, 0, 0.7);
  font-family: 'Lato', Helvetica, 'Roboto', Arial, sans-serif;
}

.modal-window .camp-schedule {
  width: 40%;
}

.container-card {
  position: relative;
  padding: 2%;
  margin: 3% 2% 5% 2%;
  border-radius: 00px;
  background-color: #fff;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
}

.container-card .painelContent {
  clear: both;
}

.container-card h1 {
  margin-bottom: 2%;
  font-size: 1.5rem;
  color: #1d1d1b;
  position: relative;
  left: 10px;
  text-transform: uppercase;
}

.btn-close {
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
  position: absolute;
}

.card-status {
  background-color: #f5f5f5;
}

.button-send-status {
  border: none;
  background-color: #f97f2c;
  color: #fff;
  padding: 7px 12px;
  border-radius: 4px;
}

.status-name {
  font-size: 1.1rem;
  font-weight: 500;
}

.button-send-status:hover {
  background-color: #f86c0e;
}
</style>
