<template>
  <div class="modal-files-preview" v-if="preview_files">
    <span @click="preview_files = false">
      <div class="btn-close"></div>
    </span>
    <video
      v-if="extension(url_file) === 'mp4' || extension(url_file) === 'mov' || extension(url_file) === 'avi'"
      :src="url_file"
      controls
      autoplay
    ></video>
    <audio v-else :src="url_file" controls autoplay></audio>
  </div>

  <div class="modal-files-preview" v-if="historic_view">
    <div class="row">
      <div class="container overflow-scroll p-5 position-relative historic-container">
        <div @click="historic_view = !historic_view" class="btn-close position-absolute top-0 end-0 m-3 fs-5"></div>
        <div
          class="alert alert-secondary alert-dismissible fade show"
          role="alert"
          v-if="formDate.historic.length === 0"
        >
          <p>Não há histórico</p>
        </div>

        <div class="container-desc-two" v-else>
          <ul class="task-desc">
            <li class="item" v-for="(historic, index) in historicFileApproved" :key="index">
              <div class="aux-row">
                <div class="user">
                  <img :src="historic.user_photo" alt="Image 001" class="owner-img" />
                  {{ historic.name_user }}
                </div>
                <div class="desc-date">
                  <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                  <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                </div>
              </div>
              <p>
                {{ statusApprovedHistoric(historic.status) }}, Plataforma: {{ historic.platform }}, Navegador:
                {{ historic.browser }}, Endereço IP: {{ historic.ip }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError" />
    <loder-send-component :text-title="'Carregando'" v-if="showLoader" />

    <div class="tabs effect-3 mt-3" v-else>
      <input checked type="radio" id="tab-1" name="tab-effect-3" />
      <span>
        <i class="info circle icon"></i>
        <p>Informações</p>
      </span>

      <input type="radio" id="tab-2" name="tab-effect-3" />
      <span id="tab-count" :data-count="formDate.files.length">
        <i class="paperclip icon"></i>
        <p>Anexos</p>
      </span>

      <input type="radio" id="tab-3" name="tab-effect-3" />
      <span id="tab-count" :data-count="formDate.descs.length">
        <i class="comment outline icon"></i>
        <p>Comentários</p>
      </span>

      <input type="radio" id="tab-4" name="tab-effect-3" />
      <span id="tab-count" :data-count="formDate.historic.length">
        <i class="clock outline icon"></i>
        <p>Histórico de interações</p>
      </span>

      <input type="radio" id="tab-5" name="tab-effect-3" />
      <span id="tab-count" :data-count="formDate.filesApproved.length">
        <i class="clipboard check icon"></i>
        <p>Arquivos aprovação</p>
      </span>

      <div class="line ease"></div>
      <div class="tab-content">
        <section id="tab-item-1">
          <form>
            <row-component>
              <row-col-component :class-row-col="'col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'">
                <div class="aux-row-2">
                  <p>Criador da tarefa:</p>
                  <img class="character-label-icon-2" :src="formDate.creator_task_photo" alt="" />
                  {{ formDate.creator_task_user }}
                </div>

                <div class="aux-row-2">
                  <p>Código:</p>
                  {{ formDate.code }}
                </div>

                <div
                  class="cont-status"
                  :class="[
                    {
                      'create-color': parseInt(formDate.status) === 1,
                      'send-color': parseInt(formDate.status) === 2,
                      'approval-color': parseInt(formDate.status) === 3,
                      'disapproved-color': parseInt(formDate.status) === 4,
                      'canceled-color': parseInt(formDate.status) === 5,
                      'finished-color': parseInt(formDate.status) === 6
                    }
                  ]"
                >
                  <p>
                    <i
                      :class="[
                        {
                          'pen square': parseInt(formDate.status) === 1,
                          send: parseInt(formDate.status) === 2,
                          coffee: parseInt(formDate.status) === 3,
                          'window close': parseInt(formDate.status) === 4,
                          'times circle': parseInt(formDate.status) === 5,
                          'check circle': parseInt(formDate.status) === 6
                        },
                        'icon'
                      ]"
                    ></i>
                    {{ loadStatusTask() }}
                  </p>
                </div>
              </row-col-component>

              <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 clearfix'">
                <div class="float-end d-flex align-items-center">
                  <button-add class="position-relative me-2" :itens="itensThree" v-if="buttonCheck.create" />

                  <button-one
                    @click="profileClient(formDate.client_id)"
                    class="me-2"
                    tooltip="Perfil do Cliente"
                    icon="user outline"
                  />

                  <button-one
                    v-if="parseInt(formDate.status) === 1"
                    @click="submitUpdateStatus(2)"
                    :icon="'send'"
                    tooltip="Enviar para responsável"
                  />

                  <button-one
                    v-if="parseInt(formDate.status) === 2 || parseInt(formDate.status) === 4"
                    @click="submitUpdateStatus(5)"
                    :icon="'times circle'"
                    tooltip="Cancelar a Tarefa"
                  />

                  <div v-if="parseInt(formDate.status) === 3" class="btn-group" role="group" aria-label="Basic example">
                    <button-one @click="submitUpdateStatus(5)" :icon="'times circle'" tooltip="Cancelar a Tarefa" />

                    <button-one @click="submitUpdateStatus(4)" :icon="'window close'" tooltip="Reprovar a Tarefa" />

                    <button-one @click="submitUpdateStatus(6)" :icon="'check circle'" tooltip="Finalizar a Tarefa" />
                  </div>
                </div>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.type_task_id }"
              >
                <label>Tipo de Tarefa:</label>
                <Multiselect
                  v-model="formDate.type_task_id"
                  :searchable="true"
                  :options="selectTypeTaskList"
                  :disabled="formDate.status >= 5"
                  :noResultsText="noResultsText"
                  :noOptionsText="noResultsText"
                />
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.responsible_task_user }"
              >
                <label>Responsável pela Tarefa:</label>

                <Multiselect
                  v-model="formDate.responsible_task_user"
                  label="name"
                  :searchable="true"
                  :options="selectUserList"
                  :disabled="formDate.status >= 5"
                  :noResultsText="noResultsText"
                  :noOptionsText="noResultsText"
                >
                  <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img class="character-label-icon" :src="value.icon" alt="" /> {{ value.name }}
                    </div>
                  </template>

                  <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon" alt="" /> {{ option.name }}
                  </template>
                </Multiselect>
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.client_id }"
              >
                <label>Cliente:</label>

                <Multiselect
                  v-model="formDate.client_id"
                  label="name"
                  :options="selectClientList"
                  :disabled="formDate.status >= 5"
                  :searchable="true"
                  :noResultsText="noResultsText"
                  :noOptionsText="noResultsText"
                >
                  <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img class="character-label-icon" :src="value.icon" alt="" /> {{ value.name }}
                    </div>
                  </template>

                  <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon" alt="" /> {{ option.name }}
                  </template>
                </Multiselect>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.priority }"
              >
                <label>Prioridade:</label>
                <Multiselect
                  v-model="formDate.priority"
                  :searchable="true"
                  :options="selectPriority"
                  :disabled="formDate.status >= 5"
                  :noResultsText="noResultsText"
                  :noOptionsText="noResultsText"
                />
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.quantity }"
              >
                <label>Quantidade:</label>
                <input-number-component
                  v-model="formDate.quantity"
                  :model-value="formDate.quantity"
                  :disabled="formDate.status >= 5"
                />
              </row-col-component>

              <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Data de Entrega:</label>
                <input-component
                  :type-input="'text'"
                  v-model="formDate.date_delivery"
                  :disabled="formDate.status >= 5"
                  :model-value="formDate.date_delivery"
                  v-mask="'##/##/####'"
                />
              </row-col-component>

              <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Hora entrega:</label>
                <input-component
                  :type-input="'text'"
                  v-model="formDate.time_delivery"
                  :disabled="formDate.status >= 5"
                  :model-value="formDate.time_delivery"
                  v-mask="'##:##:##'"
                />
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Precisa de aprovação ?</label>

                <input
                  type="radio"
                  id="aprovadoN"
                  name="aprovacao"
                  value="1"
                  v-model="formDate.approve"
                  :disabled="formDate.status >= 5"
                />
                <label for="aprovadoN">Não</label>

                <input
                  type="radio"
                  id="aprovadoS"
                  name="aprovacao"
                  value="2"
                  v-model="formDate.approve"
                  :disabled="formDate.status >= 5"
                />
                <label for="aprovadoS">Sim</label>
              </row-col-component>

              <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Informar local do arquivo ?</label>

                <input
                  type="radio"
                  id="arquivoN"
                  value="1"
                  name="arquivo"
                  v-model="formDate.inform_file_location"
                  :disabled="formDate.status >= 5"
                />
                <label for="arquivoN">Não</label>

                <input
                  type="radio"
                  id="arquivoS"
                  value="2"
                  name="arquivo"
                  v-model="formDate.inform_file_location"
                  :disabled="formDate.status >= 5"
                  checked
                />
                <label for="arquivoS">Sim</label>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Local do Arquivo:</label>
                <div class="input-group" role="button">
                  <input
                    class="form-control cursor-pointer"
                    type="text"
                    v-model="formDate.file_location"
                    @focus="$event.target.select()"
                    ref="select"
                    :disabled="formDate.status >= 5"
                  />
                  <span @click="copyUrl(formDate.file_location)" class="input-group-text my_btn" id="basic-addon1">
                    <i :class="[copy ? 'copy icon' : 'check circle icon']"></i>
                  </span>
                </div>
              </row-col-component>
            </row-component>

            <row-col-component
              :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
              :class="{ formError: errors.desc }"
            >
              <label>Descrição:</label>

              <QuillEditor
                v-if="formDate.status >= 5"
                :theme="'bubble'"
                :readOnly="true"
                :contentType="'html'"
                v-model:content="formDate.desc"
                :on-text-change="handleTextChange"
                style="min-height: 150px; margin-bottom: 2%; border: 1px solid #1b1c1d"
              />

              <QuillEditor
                v-else
                :theme="'snow'"
                :toolbar="toolbarOptions"
                :contentType="'html'"
                ref="myEditor"
                v-model:content="formDate.desc"
                :modules="modules"
                style="min-height: 150px; margin-bottom: 2%; border: 1px solid #1b1c1d"
              />
            </row-col-component>

            <div>
              <div class="line-division mt-5 mb-5">
                <h1 class="h1-text"><i class="object group outline icon"></i>Resolução</h1>
              </div>

              <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                  <table class="table table-striped">
                    <tbody>
                      <tr v-for="(format, index) in formDate.format_resolutions" :key="index">
                        <td data-label="Resolução">{{ format.resolution }}</td>
                        <td data-label="Formato">{{ returnFormat(format.format) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </row-col-component>
              </row-component>
            </div>

            <row-component>
              <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one
                  class="w-100"
                  @submit="submit"
                  :icon="'save'"
                  :name-button="'Salvar'"
                  :class="{ preloderSend: sendForm }"
                  v-if="formDate.status <= 4"
                />
              </row-col-component>

              <row-col-component
                :class="{ 'col-xxl-12 col-xl-12 col-lg-12': parseInt(formDate.status) > 4 }"
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              >
                <button-float class="w-100" :itens="itensTwo" />
              </row-col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div
            class="alert alert-secondary alert-dismissible fade show"
            role="alert"
            v-if="formDate.files.length === 0"
          >
            <p>Não há nenhum anexo</p>
          </div>

          <div>
            <div class="clearfix">
              <label class="uploadButton float-end" for="upload" tooltip="Adicionar Arquivos">
                <input type="file" ref="upload-files" @change="selectFiles" id="upload" multiple />
                <i class="icon plus"></i>
              </label>
            </div>

            <row-component>
              <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <div class="container">
                  <div class="files">
                    <ul>
                      <li
                        v-for="(data, index) in files"
                        :key="index"
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div>
                          {{ data.file.name }}
                          <span class="file-size">
                            {{ convertBytes(data.file.size) }}
                          </span>
                        </div>

                        <div
                          class="me-5 align-items-center justify-content-around"
                          style="width: 30%; display: flex"
                          :id="'progress-bar' + index"
                        >
                          <progress :value="data.progress" max="100"></progress>
                          {{ data.progress }}%
                        </div>

                        <div class="file-success" :id="'file-success' + index">
                          Sucesso ao enviar
                          <i class="check icon"></i>
                        </div>

                        <div class="file-erro" :id="'file-erro' + index">
                          Erro ao enviar
                          <i class="close icon"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                v-for="(file, index) in formDate.files"
                :key="index"
              >
                <div class="card">
                  <div class="card-image">
                    <a
                      :href="file.name_encoded"
                      target="_blank"
                      v-if="
                        extension(file.name_encoded) === 'png' ||
                        extension(file.name_encoded) === 'jpeg' ||
                        extension(file.name_encoded) === 'jpg'
                      "
                    >
                      <img :src="file.name_encoded" alt="" />
                    </a>

                    <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>
                    <i
                      v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                      class="file word icon"
                    ></i>
                    <i
                      v-else-if="
                        extension(file.name_encoded) === 'mp4' ||
                        extension(file.name_encoded) === 'mov' ||
                        extension(file.name_encoded) === 'avi'
                      "
                      class="file video icon"
                    ></i>

                    <i
                      v-else-if="
                        extension(file.name_encoded) === 'xlsx' ||
                        extension(file.name_encoded) === 'xlsm' ||
                        extension(file.name_encoded) === 'xlsb' ||
                        extension(file.name_encoded) === 'xltx' ||
                        extension(file.name_encoded) === 'xltm' ||
                        extension(file.name_encoded) === 'xls' ||
                        extension(file.name_encoded) === 'xlt' ||
                        extension(file.name_encoded) === 'xml' ||
                        extension(file.name_encoded) === 'xlam' ||
                        extension(file.name_encoded) === 'xla' ||
                        extension(file.name_encoded) === 'xlw' ||
                        extension(file.name_encoded) === 'xlr'
                      "
                      class="file excel icon"
                    ></i>

                    <i
                      v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                      class="file alternate icon"
                    ></i>

                    <i v-else class="file audio icon"></i>
                  </div>

                  <p>{{ file.name_original }}</p>

                  <div class="aux-row-3">
                    <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                    <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                  </div>

                  <div class="ow-img">
                    <img :src="file.user_photo" alt="" />
                    Enviado por: {{ file.user_name }}
                  </div>

                  <div class="dates-card">
                    <button
                      v-if="
                        extension(file.name_encoded) === 'png' &&
                        extension(file.name_encoded) === 'jpeg' &&
                        extension(file.name_encoded) === 'jpg'
                      "
                      class="btn btn-secondary buttonRound"
                      type="button"
                      @click="show(index)"
                    >
                      <i class="eye icon"></i>
                    </button>

                    <button
                      v-else
                      class="btn btn-secondary buttonRound"
                      type="button"
                      @click="previewFiles(file.name_encoded)"
                    >
                      <i class="eye icon"></i>
                    </button>

                    <button
                      type="button"
                      class="btn btn-danger buttonRound"
                      v-if="formDate.status <= 4"
                      @click="showDeleteActionFile(index)"
                    >
                      <i class="trash icon"></i>
                    </button>

                    <div :class="[{ showDeleteConfirm: index === showDelete }, 'deleteConfirm']">
                      <div class="closeBtn" @click="hideDeleteActionFile()">X</div>
                      <p>A exclusão é permanente. Não é possível desfazer.</p>
                      <div class="d-grid gap-2">
                        <button class="btn btn-danger btn-sm btnReset" type="button" @click="deleteSubmitFile(file)">
                          Excluir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </row-col-component>
            </row-component>
          </div>
        </section>

        <section id="tab-item-3">
          <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
              <label>Comentários:</label>
              <input-text-area
                :rows="4"
                v-model="formDateObs.obs"
                :disabled="parseInt(formDate.status) === 5 || parseInt(formDate.status) === 6"
              />
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
              <div class="clearfix">
                <button-one
                  class="float-end"
                  @submit="submitObs"
                  :icon="'plus'"
                  :name-button="'Adicionar'"
                  v-if="formDate.status <= 4"
                  :class="{ preloderSend: sendForm }"
                />
              </div>
            </row-col-component>
          </row-component>

          <div class="merito" v-if="formDate.descs.length !== 0">
            <section class="container-desc">
              <ul class="task-desc">
                <li class="item" v-for="(obs, index) in formDate.descs" :key="index">
                  <div class="aux-row">
                    <div class="user">
                      <img :src="obs.user_photo" alt="Image 001" class="owner-img" />
                      {{ obs.user_name }}
                    </div>
                    <div class="desc-date">
                      <div class="text"><i class="calendar icon"></i>{{ obs.date_create }}</div>
                      <div class="text"><i class="clock icon"></i>{{ obs.hour_create }}</div>
                    </div>
                  </div>
                  <p>{{ obs.obs }}</p>
                  <div :class="[{ showDeleteObsConfirm: index === showDeleteObs }, 'deleteObsConfirm']">
                    <div class="closeBtn" @click="hideDeleteActionObs()">X</div>
                    <p>A exclusão é permanente. Não é possível desfazer.</p>
                    <div class="d-grid gap-2">
                      <button class="btn btn-danger btn-sm btnReset" type="button" @click="deleteSubmitObs(obs)">
                        Excluir
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </section>

        <section id="tab-item-4">
          <div
            class="alert alert-secondary alert-dismissible fade show"
            role="alert"
            v-if="formDate.historic.length === 0"
          >
            <p>Não há histórico</p>
          </div>

          <div class="container-desc-two">
            <ul class="task-desc">
              <li class="item" v-for="(historic, index) in formDate.historic" :key="index">
                <div class="aux-row">
                  <div class="user">
                    <img :src="historic.user_photo" alt="Image 001" class="owner-img" />
                    {{ historic.name_user }}
                  </div>
                  <div class="desc-date">
                    <div class="text"><i class="calendar icon"></i>{{ historic.date_create }}</div>
                    <div class="text"><i class="clock icon"></i>{{ historic.hour_create }}</div>
                  </div>
                </div>
                <p>
                  {{ statusHistoric(historic.status) }}, Plataforma: {{ historic.platform }}, Navegador:
                  {{ historic.browser }}, Endereço IP: {{ historic.ip }}
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section id="tab-item-5">
          <div
            class="alert alert-secondary alert-dismissible fade show"
            role="alert"
            v-if="formDate.filesApproved.length === 0"
          >
            <p>Não há nada cadastrado</p>
          </div>

          <div class="clearfix" v-if="formDate.filesApproved.length > 0">
            <div
              v-if="formDate.status !== '5' || formDate.status !== '6'"
              class="btn-group float-end"
              role="group"
              aria-label="Basic example"
            >
              <button
                :disabled="approved_reproach.task_files_ids.length === 0 || this.approved_disabled"
                @click="approvedSet"
                class="btn buttonColorOrange"
              >
                Aprovar
              </button>
              <button
                :disabled="approved_reproach.task_files_ids.length === 0 || this.reproach_disabled"
                @click="reproachSet"
                class="btn buttonColorOrange"
              >
                Reprovar
              </button>
            </div>

            <div v-if="formDate.filesApproved.length > 0" class="btn-group" role="group">
              <button-one icon="send" @click="filterApproved(1)" tooltip="Enviados" />
              <button-one icon="close" @click="filterApproved(2)" tooltip="Reprovados" />
              <button-one icon="check" @click="filterApproved(3)" tooltip="Aprovados" />
              <button-one icon="reply all" @click="loadPageItens" tooltip="Listar Todos" />
            </div>
          </div>

          <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
              <div class="container">
                <form>
                  <div class="files">
                    <ul>
                      <li v-for="(file, index) in formDateFile" :key="index">
                        {{ file.name }}
                        <span class="file-size">{{ convertBytes(file.size) }} {{ suffix }}</span>
                        <div class="spinner rounded-circle" :id="'loaderFile' + index"></div>
                        <div class="file-success" :id="'file-success' + index">
                          Sucesso ao enviar
                          <i class="check icon"></i>
                        </div>
                        <div class="file-erro" :id="'file-erro' + index">
                          Erro ao enviar
                          <i class="close icon"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </row-col-component>
          </row-component>

          <row-component>
            <row-col-component
              :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'"
              v-for="(file, index) in formDate.filesApproved"
              :key="index"
            >
              <div class="__item">
                <input
                  :value="file.id"
                  v-model="approved_reproach.task_files_ids"
                  type="checkbox"
                  name="cards"
                  :id="'cb' + index"
                />

                <label :for="'cb' + index">
                  <div class="card">
                    <div class="card-image">
                      <div
                        v-if="
                          extension(file.name_encoded) === 'png' ||
                          extension(file.name_encoded) === 'jpeg' ||
                          extension(file.name_encoded) === 'jpg'
                        "
                      >
                        <img :src="file.name_encoded" alt="" />
                      </div>

                      <i v-else-if="extension(file.name_encoded) === 'pdf'" class="file pdf icon"></i>
                      <i
                        v-else-if="extension(file.name_encoded) === 'docx' || extension(file.name_encoded) === 'doc'"
                        class="file word icon"
                      ></i>
                      <i
                        v-else-if="
                          extension(file.name_encoded) === 'mp4' ||
                          extension(file.name_encoded) === 'mov' ||
                          extension(file.name_encoded) === 'avi'
                        "
                        class="file video icon"
                      ></i>

                      <i
                        v-else-if="
                          extension(file.name_encoded) === 'xlsx' ||
                          extension(file.name_encoded) === 'xlsm' ||
                          extension(file.name_encoded) === 'xlsb' ||
                          extension(file.name_encoded) === 'xltx' ||
                          extension(file.name_encoded) === 'xltm' ||
                          extension(file.name_encoded) === 'xls' ||
                          extension(file.name_encoded) === 'xlt' ||
                          extension(file.name_encoded) === 'xml' ||
                          extension(file.name_encoded) === 'xlam' ||
                          extension(file.name_encoded) === 'xla' ||
                          extension(file.name_encoded) === 'xlw' ||
                          extension(file.name_encoded) === 'xlr'
                        "
                        class="file excel icon"
                      ></i>

                      <i
                        v-else-if="extension(file.name_encoded) === 'txt' || extension(file.name_encoded) === 'csv'"
                        class="file alternate icon"
                      ></i>

                      <i v-else class="file audio icon"></i>
                    </div>

                    <p class="m-0">{{ file.name_original }}</p>

                    <div class="aux-row-3">
                      <div class="text"><i class="calendar icon"></i>{{ file.date_create }}</div>
                      <div class="text"><i class="clock icon"></i>{{ file.hour_create }}</div>
                    </div>

                    <div class="container ps-3">
                      <div
                        class="tag-priority"
                        :class="[
                          {
                            'create-color': file.to_approve === '1' || file.status === '1',
                            'disapproved-color': file.to_approve === '2' || file.status === '2',
                            'send-color': file.to_approve === '3' || file.status === '3',
                            'finished-color': file.to_approve === '4' || file.status === '4'
                          }
                        ]"
                      >
                        {{ statusApprovedHistoric(file.to_approve || file.status) }}
                      </div>
                    </div>

                    <div class="ow-img">
                      <img :src="file.user_photo" alt="" />
                      Enviado por: {{ file.user_name }}
                    </div>

                    <div class="card-container-buttons">
                      <div>
                        <button
                          class="btn"
                          v-if="
                            extension(file.name_encoded) === 'png' ||
                            extension(file.name_encoded) === 'jpeg' ||
                            extension(file.name_encoded) === 'jpg'
                          "
                          type="button"
                          @click="show(index)"
                        >
                          <i class="eye icon"></i>
                        </button>

                        <button class="btn" v-else type="button" @click="previewFiles(file.name_encoded)">
                          <i class="eye icon"></i>
                        </button>

                        <button
                          :data-count="file.historic_total"
                          @click="historicViewApproved(file.id)"
                          class="btn file-historic_total"
                          type="button"
                        >
                          <i class="history icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </row-col-component>
          </row-component>
        </section>
      </div>
    </div>
  </painel-component>

  <div>
    <vue-easy-lightbox :visible="visibleRef" :imgs="photo" :index="indexRef" @hide="onHide" />
  </div>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import InputNumberComponent from '@/components/form/InputNumberComponent';
import ButtonFloat from '@/components/buttons/ButtonFloat';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import Multiselect from '@vueform/multiselect';
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import LoderSendComponent from '@/components/LoderSendComponent';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import { ACTION_MESSAGES, FORMATOS } from '@/configs/constants';
import { mask } from 'vue-the-mask';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';
import task from '@/router/modules/task';
import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';
import axios from 'axios';
import ImageUploader from 'quill-image-uploader';
import { ref } from 'vue';

const toast = useToast();

export default {
  name: 'TaskUpdate',
  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    InputNumberComponent,
    ButtonFloat,
    ButtonOne,
    ButtonAdd,
    Multiselect,
    ErrorComponent,
    LoderSendComponent,
    QuillEditor,
    InputTextArea,
    VueEasyLightbox
  },

  directives: { mask },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.loadPageItens();
    this.checkACL();
  },

  setup(props) {
    const { show, onHide, changeIndex, visibleRef, indexRef, imgsRef } = useEasyLightbox({ initIndex: 0 });

    const addresses = ref([]);

    const modules = {
      name: 'imageUploader',
      module: ImageUploader,
      options: {
        upload: file => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id', props.id);
            axios
              .post(`/api/v1/admin/task-file`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                responseType: 'json'
              })
              .then(res => {
                const idImg = res.data[0].name_encoded;
                addresses.value.push(idImg);
                resolve(idImg);
              })
              .catch(() => {
                reject('Upload failed');
              });
          });
        }
      }
    };

    return {
      visibleRef,
      indexRef,
      imgsRef,
      show,
      onHide,
      changeIndex,
      modules,
      addresses
    };
  },

  data() {
    return {
      itensOne: {
        title: 'Adicionar',
        icon: 'pen square'
      },

      itensTwo: {
        route: '/task/search',
        icon: 'reply all',
        name: 'Voltar'
      },

      itensThree: {
        route: '/task/create',
        icon: 'plus',
        name: 'Adicionar'
      },

      buttonCheck: {
        create: false
      },

      formDate: {
        sector: '',
        creator_task_photo: '',
        creator_task_user: '',
        type_task_id: '',
        platform: '',
        responsible_task_user: '',
        client_id: '',
        priority: '',
        quantity: '',
        inform_file_location: '',
        approve: '',
        file_location: '',
        date_delivery: '',
        time_delivery: '',
        desc: '',
        status: '',
        files: [],
        descs: [],
        historic: [],
        filesApproved: [],
        format_resolutions: []
      },

      formDateObs: {
        id: this.id,
        obs: ''
      },

      formDateStatus: {
        id: this.id,
        status: ''
      },

      showDelete: false,
      showDeleteObs: false,

      errors: [],
      showError: false,
      sendForm: false,
      showLoader: true,

      toolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction
        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ['clean'] // remove formatting button
      ],

      images: '',
      photo: [],

      url_file: '',
      preview_files: false,

      files: [],

      formDateFile: [],
      formDateUpdaloadFile: {
        id: this.id,
        file: ''
      },

      historic_view: false,
      formDateHistoric: {
        id: this.id,
        fileid: ''
      },
      historicFileApproved: [],

      approved_reproach: {
        id: this.id,
        task_files_ids: []
      },

      approved_disabled: false,
      reproach_disabled: false,
      input_disabled: '',

      suffix: '',
      size: '',
      contUpload: 0,

      copy: true
    };
  },

  methods: {
    returnFormat(format) {
      return FORMATOS[format];
    },

    getImages() {
      for (let p of this.images) {
        this.photo.push(p.name_encoded);
      }
      return this.photo;
    },

    extension(name) {
      return name.split('.').pop().toString();
    },

    historicViewApproved(id) {
      this.formDateHistoric.fileid = id;

      this.$store
        .dispatch('loadTaskFileApprovedHistoric', this.formDateHistoric)
        .then(r => {
          this.historicFileApproved = r;
          this.historic_view = true;
        })
        .catch(() => {
          toast.error('Erro desconhecido !', { timeout: 2000 });
        });
    },

    profileClient(id) {
      if (!this.formDate.profile_client) {
        toast.error('Ops!, perfil do cliente não foi cadastrado.', { timeout: 2000 });
      } else {
        this.$router.push({ name: 'profile-client-show', params: { id: id } });
      }
    },

    filterApproved(params) {
      let formData = {
        id: this.id,
        status: params
      };
      this.$store
        .dispatch('loadTaskFileApprovedStatus', formData)
        .then(r => {
          if (r.length === 0) {
            if (params === 1) toast.info('Nenhum arquivo enviado foi encontrado!', { timeout: 2000 });
            if (params === 2) toast.info('Nenhum arquivo reprovado foi encontrado!', { timeout: 2000 });
            if (params === 3) toast.info('Nenhum arquivo aprovado foi encontrado!', { timeout: 2000 });
          } else {
            let file = r;
            file.forEach(item => {
              delete Object.assign(item, { ['status']: item['to_approve'] })['to_approve'];
            });
            if (params === 2) {
              this.reproach_disabled = true;
              this.approved_disabled = false;
            }

            if (params === 3) {
              this.approved_disabled = true;
              this.reproach_disabled = false;
            }

            this.formDate.filesApproved = file;
          }
        })
        .catch(() => {
          toast.error('Erro desconhecido !', { timeout: 2000 });
        });
    },

    approvedSet() {
      this.$store
        .dispatch('approvedSetFilesTask', this.approved_reproach)
        .then(() => {
          toast.success('Arquivos aprovados com sucesso', { timeout: 2000 });
          this.approved_reproach.task_files_ids = [];
          this.loadPageItens();
        })
        .catch(() => {
          toast.success('Erro ao aprovar arquivos', { timeout: 2000 });
        });
    },

    reproachSet() {
      this.$store
        .dispatch('reproachSetFilesTask', this.approved_reproach)
        .then(() => {
          toast.success('Arquivos aprovados com sucesso', { timeout: 2000 });
          this.approved_reproach.task_files_ids = [];
          this.loadPageItens();
        })
        .catch(() => {
          toast.success('Erro ao aprovar arquivos', { timeout: 2000 });
        });
    },

    previewFiles(file) {
      let ext = file.split('.').pop();
      let types = ['wav', 'aiff', 'pcm', 'flac', 'alac', 'wma', 'mp3', 'ogg', 'aac', 'mp4', 'mov', 'avi'];

      if (types.find(element => element === ext)) {
        this.url_file = file;
        this.preview_files = true;
      } else {
        window.open(file, '_blank');
      }
    },

    submit() {
      if (this.formDate.status >= 5) {
        toast.error('Não é possível fazer alterações!', { timeout: 2000 });
      } else if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateTask', this.formDate)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.loadPageItens();
            toast.success(ACTION_MESSAGES.update, { timeout: 2000 });
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.scrollTop();
            this.loadPageItens();
            toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
          });
      }
    },

    submitObs() {
      this.$store
        .dispatch('storeTaskObs', this.formDateObs)
        .then(r => {
          this.showError = false;
          this.errors = [];
          this.formDate.descs = r.data;
          this.formDateObs.obs = '';
          toast.success(ACTION_MESSAGES.create, { timeout: 2000 });
        })
        .catch(r => {
          this.showError = true;
          this.errors = r.response.data.errors;
          this.scrollTop();
          toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
        });
    },

    submitUpdateStatus(status) {
      this.formDateStatus.status = status;
      if (parseInt(this.formDateStatus.status) === 5) {
        swal({
          title: 'Atenção!',
          text: `Deseja realmente cancelar a tarefa ?`,
          closeOnClickOutside: false,
          buttons: {
            cancel: {
              text: 'Não',
              visible: true,
              closeModal: true
            },
            confirm: 'Sim'
          }
        }).then(result => {
          if (result) {
            this.formDateStatus.status = 5;
            this.formDateStatus.id = task.id;
            this.$store
              .dispatch('storeTaskStatus', this.formDateStatus)
              .then(() => {
                if (parseInt(this.formDateStatus.status) === 5) {
                  this.loadTasks();
                  toast.success('Tarefa cancelada com sucesso', { timeout: 2000 });
                }
              })
              .catch(() => {
                toast.error('Não foi possível alterar o status!', { timeout: 2000 });
              });
          }
        });
      } else {
        this.$store
          .dispatch('storeTaskStatus', this.formDateStatus)
          .then(() => {
            if (parseInt(this.formDateStatus.status) === 2) {
              toast.success('Tarefa enviada para o responsável com sucesso', { timeout: 2000 });
              this.formDate.status = 2;
            }
            if (parseInt(this.formDateStatus.status) === 4) {
              toast.success('Tarefa foi reprovada com sucesso', { timeout: 2000 });
              this.formDate.status = 4;
            }

            if (parseInt(this.formDateStatus.status) === 6) {
              toast.success('Tarefa finalizada com sucesso', { timeout: 2000 });
              this.formDate.status = 6;
            }
          })
          .catch(() => {
            toast.error('Não foi possível alterar o status!', { timeout: 2000 });
          });
      }
    },

    loadPageItens() {
      this.$store.dispatch('updateNamePage', 'Tarefa');
      this.$store.dispatch('loadTypeTasksSelect');
      this.$store.dispatch('loadUserListSelect');
      this.$store.dispatch('loadClientListSelect');
      this.$store
        .dispatch('loadTask', this.id)
        .then(r => {
          this.formDate = r;
          this.images = r.filesApproved;
          this.getImages();
          this.$refs.myEditor?.setHTML(r.desc);
        })
        .catch(() => {
          toast.error('Erro desconhecido !', { timeout: 2000 });
        });
    },

    scrollTop() {
      window.scrollTo(0, 0);
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 8)
        .then(r => {
          if (r.data.indexOf('task-edit') !== -1) {
            this.showLoader = false;
          }
          if (r.data.indexOf('task-create') !== -1) {
            this.buttonCheck.create = true;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    loadStatusTask() {
      if (parseInt(this.formDate.status) === 1) return 'Criando';
      if (parseInt(this.formDate.status) === 2) return 'Enviado para Responsável';
      if (parseInt(this.formDate.status) === 3) return 'Enviado para aprovação';
      if (parseInt(this.formDate.status) === 4) return 'Reprovado';
      if (parseInt(this.formDate.status) === 5) return 'Cancelada';
      if (parseInt(this.formDate.status) === 6) return 'Finalizado';
    },

    statusHistoric(status) {
      if (parseInt(status) === 1) {
        return 'Criando';
      }
      if (parseInt(status) === 2) {
        return 'Enviado para Responsável';
      }
      if (parseInt(status) === 3) {
        return 'Enviado para aprovação';
      }
      if (parseInt(status) === 4) {
        return 'Reprovado';
      }
      if (parseInt(status) === 5) {
        return 'Cancelado';
      }
      if (parseInt(status) === 6) {
        return 'Finalizado';
      }
    },

    statusApprovedHistoric(status) {
      if (parseInt(status) === 1) {
        return 'Enviado';
      }
      if (parseInt(status) === 2) {
        return 'Reprovado';
      }
      if (parseInt(status) === 3) {
        return 'Aprovado';
      }
      if (parseInt(status) === 4) {
        return 'Atualizado';
      }
    },

    deleteSubmitFile(item) {
      this.$store
        .dispatch('destroyTaskFile', item.id)
        .then(() => {
          this.$store.dispatch('loadTask', this.id).then(r => {
            this.formDate = r;
            // document.location.reload()
            this.loadPageItens();
            this.$refs.myEditor.setHTML(r.desc);
          });
          toast.success(ACTION_MESSAGES.delete, { timeout: 1000 });
        })
        .catch(() => {
          toast.error(`Não foi possível deletar  ${item.name} `, { timeout: 1000 });
        })
        .finally(() => {
          this.hideDeleteActionFile();
        });
    },

    deleteSubmitObs(item) {
      this.$store
        .dispatch('destroyTaskObs', item.id)
        .then(() => {
          this.$store.dispatch('loadTask', this.id).then(r => {
            this.formDate = r;
            this.$refs.myEditor.setHTML(r.desc);
          });
          toast.success(ACTION_MESSAGES.delete, { timeout: 1000 });
        })
        .catch(() => {
          toast.error(`Não foi possível deletar  ${item.name} `, { timeout: 1000 });
        })
        .finally(() => {
          this.hideDeleteActionObs();
        });
    },

    showDeleteActionFile(index) {
      this.showDelete = index;
    },

    hideDeleteActionFile() {
      this.showDelete = false;
    },

    hideDeleteActionObs() {
      this.showDeleteObs = false;
    },

    // #################################################

    selectFiles(event) {
      this.files = Array.from(event.target.files).map(file => ({
        file,
        id: this.id
      }));

      this.uploadFiles();
    },

    uploadFiles() {
      if (this.formDate.status >= 5) {
        this.files = [];
        toast.error('Não é possível fazer alterações!', { timeout: 2000 });
      } else {
        this.sendForm = true;
        let removeFileClass = document.getElementsByClassName('remove-file');
        removeFileClass.forEach(element => {
          element.style.display = 'none';
        });

        this.files.forEach((fileObject, index) => {
          const params = {
            file: fileObject.file,
            id: this.id
          };

          axios
            .post('api/v1/admin/task-file', params, {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: progressEvent => {
                fileObject.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              }
            })
            .then(() => {
              document.getElementById('file-success' + index).style.display = 'block';
              document.getElementById('progress-bar' + index).style.display = 'none';
              fileObject.progress = 0;
              this.contUpload += 1;
              if (this.contUpload === this.files.length) {
                this.loadPageItens();
                this.sendForm = false;
              }
            })
            .catch(() => {
              document.getElementById('file-erro' + index).style.display = 'block';
              document.getElementById('progress-bar' + index).style.display = 'none';
              fileObject.progress = 0;
              this.contUpload += 1;
              if (this.contUpload === this.files.length) {
                this.loadPageItens();
                this.sendForm = false;
              }
            });
        });
      }
    },

    convertBytes(size) {
      if (size >= 1024 && size < 1024000) {
        size = Math.round((size / 1024) * 100) / 100;
        return size;
      } else if (size >= 1024000) {
        size = Math.round((size / 1024000) * 100) / 100;
        return size;
      }
    },

    copyUrl(url) {
      navigator.clipboard.writeText(url);
      this.$refs.select.focus();
      this.copy = false;
      setTimeout(() => {
        this.copy = true;
      }, 2000);
    }

    // Função para sincronizar os arrays
    // sincronizarArrays() {
    //     let images = [];
    //     let files = [];
    //
    //     let div = document.createElement('div')
    //     div.innerHTML = this.formDate.desc
    //
    //     let array1 = this.formDate.files;
    //     let array2 = div.querySelectorAll('img');
    //
    //     array1.forEach((el) => {
    //         images.push(el.name_encoded)
    //     })
    //
    //     array2.forEach((el) => {
    //         files.push(el.currentSrc)
    //     })
    //
    //     let array1Copia = [...images]; //Anexos
    //     let array2Copia = [...files]; //Quill
    //
    //     for (let i = array1Copia.length - 1; i >= 0; i--) {
    //         if (!array2Copia.includes(array1Copia[i])) {
    //             // Se o elemento não estiver em array2, remova-o de array1
    //             array1Copia.splice(i, 1);
    //         }
    //     }
    //
    //     // Itere sobre os elementos do array2
    //     for (let i = array2Copia.length - 1; i >= 0; i--) {
    //         if (!array1Copia.includes(array2Copia[i])) {
    //             // Se o elemento não estiver em array1, remova-o de array2
    //             array2Copia.splice(i, 1);
    //         }
    //     }
    // }
  },

  computed: {
    noResultsText() {
      return this.$store.state.var.selectNotSearch;
    },

    selectPlatform() {
      return this.$store.state.var.selectPlatform;
    },

    selectPriority() {
      return this.$store.state.var.selectPriority;
    },

    selectTypeTaskList() {
      return this.$store.state.var.selectTypeTaskList;
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },

    selectClientList() {
      return this.$store.state.var.selectClientList;
    },

    selectSector() {
      return this.$store.state.var.selectSector;
    }
  }
};
</script>

<style scoped>
.tag-priority {
  border-radius: 10px;
  padding: 0 15px;
  color: #fff;
  text-align: right;
  font-size: 13px;
  font-weight: bold;
  width: fit-content;
}

input[type='checkbox'][id^='cb'] {
  display: none;
}

.__item label {
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.__item label:before {
  background-color: white;
  color: white;
  content: ' ';
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: 0;
  left: 5%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 22px;
  z-index: 1;
  transition-duration: 0.4s;
  transform: scale(0);
}

.__item input:checked + label:before {
  content: '✓';
  background-color: grey;
  transform: scale(1);
}

.__item input[type='checkbox'] + label::after {
  display: none;
}

.__item input:checked + label {
  transform: scale(1);
  z-index: -1;
}

.card-container-buttons {
  height: 60px;
  width: 100%;
  position: relative;
}

.card-container-buttons > div {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.card-container-buttons > div label {
  padding-top: 12px;
}

.card-container-buttons > div button,
.card-container-buttons > div label {
  width: calc(100% / 2);
  height: 50px;
  background: #f5f5f5;
  border: 1px solid #fdfdfd;
  border-radius: 0;
  color: #7e7e7e;
}

.file-historic_total:after,
#tab-count:after {
  top: 25%;
  width: 20px;
  right: 15px;
  height: 20px;
  display: flex;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  background: #ff851b;
  align-items: center;
  justify-content: center;
  content: attr(data-count);
}

.historic-container {
  width: 95%;
  height: 700px;
  overflow: scroll;
  background: #ffffff;
}

@media (max-width: 1366px) {
  #tab-count:after {
    top: 0;
    right: 2px;
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 991px) {
  #tab-count:after {
    top: 0;
    right: 0;
  }

  .historic-container {
    width: 95%;
    height: 700px;
    overflow: scroll;
  }
}

.merito > .container-desc {
  display: block;
}

.merito {
  width: 100%;
  height: 200px;
  padding: 0 20px;
  background: #ebebeb;
  overflow-x: scroll;
}

.desc-date {
  width: 280px;
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
}

.character-label-icon,
.character-option-icon {
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.character-label-icon-2 {
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.cont-status {
  margin-top: 20px;
  width: fit-content;
}

.character-label-icon,
.character-option-icon {
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.character-label-icon-2 {
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.cont-status {
  margin-top: 20px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
}

.line-division {
  position: relative;
  width: 100%;
  border-top: 1px solid #c4c4c4;
}

.h1-text {
  margin-top: 1px;
  width: fit-content;
  padding: 10px;
  background-color: #d67c1c;
  color: white;
  font-size: 16px;
}

.aux-row {
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: space-between;
}

.aux-row-2 {
  display: flex;
  width: 98%;
  align-items: center;
}

.aux-row-2 p {
  margin: 0 10px 0 0;
}

.buttonRound {
  border-radius: 50px;
  font-size: 15px;
  width: 35px;
  height: 35px;
  margin-right: 7px;
  padding: 6px;
}

.deleteConfirm {
  display: none;
  position: absolute;
  bottom: 0;
  background-color: rgb(247 247 247);
  padding: 20px;
  width: 269px;
  font-family: inherit;
}

.deleteObsConfirm {
  right: 100px;
  display: none;
  position: absolute;
  background-color: rgb(247 247 247);
  padding: 20px;
  width: 269px;
  font-family: inherit;
  top: 5px;
}

.deleteObsConfirm::before,
.deleteConfirm::before {
  position: absolute;
  content: '';
  right: calc(50% - 10px);
  top: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e2e2e2 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.showDeleteConfirm {
  z-index: 1;
  display: block;
}

.showDeleteObsConfirm {
  z-index: 2;
  display: block;
}

.btnReset {
  border-radius: 0;
}

.closeBtn {
  top: 3px;
  right: 11px;
  cursor: pointer;
  position: absolute;
}

.create-color {
  background-color: #5a5a5a;
}

.send-color {
  background-color: #16ab39;
}

.approval-color {
  background-color: #2185d0;
}

.disapproved-color {
  background-color: #db2828;
}

.canceled-color {
  background-color: #db2828;
}

.finished-color {
  background-color: #b5cc18;
}

@media (max-width: 991px) {
  .aux-row {
    flex-direction: column;
    width: 100%;
    align-items: normal;
  }

  .deleteObsConfirm {
    right: unset;
  }
}
</style>

<style scoped>
.card-image {
  display: block;
  object-fit: contain;
  text-align: center;
}

.card-image i.icon {
  display: initial;
  font-size: 8rem;
}

.card-image div > img,
.card-image a > img {
  display: block;
  width: 100%;
}

.card {
  position: relative;
  display: inline-block;
  width: 90%;
  min-height: auto;
  max-width: 20rem;
  margin: 1rem;
  font-size: 1rem;
  text-decoration: none;
  box-shadow: 0 0 3rem -1rem rgba(0, 0, 0, 0.5);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s;
}

.card:hover {
  transform: translateY(-0.5rem) scale(1.0125);
  box-shadow: 0 0.5em 3rem -1rem rgba(0, 0, 0, 0.5);
}

.card p {
  margin-top: 5px;
  margin-bottom: 70px;
  text-align: center;
}

.ow-img {
  padding: 10px 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
}

.ow-img > img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.aux-row-3 {
  display: flex;
  justify-content: space-evenly;
}

.dates-card {
  display: flex;
  place-items: center;
  place-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s;
}

.dates-card:hover {
  opacity: 1;
  transition: opacity 0.5s;
}
</style>

<style scoped>
input[type='file'] {
  position: absolute;
  right: -9999px;
  visibility: hidden;
  opacity: 0;
}

input[type='button'] {
  position: relative;
  padding: 1rem 3rem;
  background: #ff7700;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  border: 0;
  color: #fff;
}

div.files {
  padding: 1rem 0;
  margin: 1rem 0;
}

div.files ul {
  list-style: none;
  padding: 0;
  max-height: 160px;
  overflow: auto;
}

div.files ul li {
  width: 99%;
  background: #eee;
  margin: 0.5rem 0;
  padding: 0.5rem;
  position: relative;
}

div.files ul li button {
  border: 0;
  outline: none;
  position: absolute;
  right: 0;
  margin-right: 0.5rem;
}

div.container {
  max-width: 100%;
  padding: 0;
}

span.file-size {
  color: #999;
  padding-left: 0.5rem;
}

.uploadButton {
  border: 0;
  color: #fff;
  height: 42px;
  padding: 0.7rem;
  cursor: pointer;
  line-height: 20px;
  border-radius: 5px;
  background: #d67c1c;
  transition: background-color 0.5s ease-out;
}

.file-success > i.icon {
  color: #16ab39;
}

.file-erro > i.icon {
  color: #db2828;
}

.file-success,
.file-erro {
  display: none;
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
}

@keyframes Rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: none;
  margin: 0.5rem;
  width: 25px;
  height: 25px;
  border: 4px solid lightgray;
  border-top-color: gray;
  animation: Rotate 1.2s linear 0s infinite forwards;
  position: absolute;
  top: 0;
  right: 0;
}

.my_btn {
  cursor: pointer;
  position: relative;
  background: #d67c1c;
  color: white;
  border: 1px solid #d67c1c;
  padding-bottom: 15px;
}
</style>

<style scoped>
.modal-files-preview {
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  height: 100vh;
  display: grid;
  position: fixed;
  place-items: center;
  place-content: center;
  background: rgb(0, 0, 0, 0.6);
}

.modal-files-preview .btn-close {
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
  position: absolute;
}

.modal-files-preview span {
  top: 0;
  right: 0;
  margin: 2%;
  width: 50px;
  height: 50px;
  display: block;
  background: white;
  position: absolute;
  border-radius: 50%;
  padding: 0.4rem 0.5rem;
}

.modal-files-preview .btn-close {
  font-size: 1.5rem;
  position: relative;
}

.modal-files-preview > video {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
  display: block;
  max-height: 80vh;
  max-width: 80vw;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
}

progress {
  border-radius: 8px;
  border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
  background-color: #fbfbfb;
}

progress::-webkit-progress-value {
  background-color: #d67c1c;
}
</style>
