<template>
    <div class="modal-budget" v-if="modal_client || modal_email || modal_phone || modal_provider">
        <div v-if="modal_client">
            <div class="btn-close" @click="openClient()"></div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Cliente:</label>
                <Multiselect v-model="client" label="name" :options="selectClientList"
                             :searchable="true"
                             :noResultsText="noResultsText" :noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
            <button-one class="p-2" name-button="Confirmar" @click="closeClient(client)"/>
        </div>

        <div v-if="modal_provider">
            <div class="btn-close" @click="openProvider()"></div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Fornecedor:</label>
                <Multiselect v-model="provider"  :options="selectProvider"
                            :searchable="true"
                             :noResultsText="noResultsText" :noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt="">{{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt="">{{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
            <button-one class="p-2" name-button="Confirmar" @click="closeProvider(provider)"/>
        </div>

        <div v-if="modal_email">
            <div class="btn-close" @click="openEmail()">X</div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Email:</label>
                <Multiselect v-model="formDate.email" label="name" :options="selectClientEmailList"
                             :noResultsText="noResultsText" :noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
            <button-one class="p-2" name-button="Confirmar" @click="openEmail"/>
        </div>

        <div v-if="modal_phone">
            <div class="btn-close" @click="openPhone()"></div>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Telefone:</label>
                <Multiselect v-model="formDate.phone" label="name" :options="selectClientPhoneList"
                             :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
            <button-one class="p-2" name-button="Confirmar" @click="openPhone"/>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="money bill alternate outline icon"></i>
                <p>Orçamento</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form autocomplete="off">
                        <row-component>
                            <row-col-component :class="{ 'formError': errors.budget_template_id }"
                                               :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                <label>Modelo de Orçamento:</label>
                                <multiselect v-model="formDate.budget_template_id" :searchable="true"
                                             @select="getBudgetTemplate(formDate.budget_template_id)"
                                             :options="selectBudgetTemplate"
                                             :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.valid }">
                                <label>Dias Válido:</label>
                                <input-number-component :type-input="'number'" min="15" v-model="formDate.valid"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class="{ 'formError': errors.name }"
                                               :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="formDate.name"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class="{ 'formError': errors.client_name }"
                                               :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Cliente:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.client_name">
                                    <span @click="openClient()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Fornecedor:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.provider_name">
                                    <span @click="openProvider()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component :class="{ 'formError': errors.show_provider }"
                                               :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-2 mt-4'">
                                <label>Exibir Fornecedor?</label>

                                <input type="radio" id="show-provide" v-model="formDate.show_provider" value="1">
                                <label for="show-provide">Não</label>

                                <input type="radio" id="not-show-provide" v-model="formDate.show_provider" value="2">
                                <label for="not-show-provide">Sim</label>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.responsible }">
                                <label>Email:</label>
                                <div class="input-group">
                                    <input class="form-control" type="text" v-model="formDate.email">
                                    <span @click="openEmail()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <div class="input-group">
                                    <input v-mask="['(##) ####-####', '(##) #####-####']" class="form-control"
                                           type="text"
                                           v-model="formDate.phone">
                                    <span @click="openPhone()" class="input-group-text my_btn" id="basic-addon1">
                                        <i class="search icon"></i>
                                    </span>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component :class="{ 'formError': errors.responsible }"
                                               :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Responsável</label>
                                <input-component :type-input="'text'" v-model="formDate.responsible"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <div class="clearfix">
                                <button-one class="float-end rounded-0" icon="plus" @click="addBudget"/>
                            </div>

                            <div class="border-content" v-for="(budget, index) in budgetAdd" :key="index">
                                <form autocomplete="off">
                                    <button-remove-round v-if="budgetAdd.length > 1" @click="budgetRemove(index)"
                                                         class="position-absolute rounded-0 end-0 bottom-0 m-2"/>

                                    <row-component>
                                        <row-col-component
                                                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                            <label>Produto/Serviço:</label>
                                            <input-component :type-input="'text'" v-model="budget.name"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                            <label>Quantidade:</label>
                                            <input-number-component :type-input="'number'" min="1" placeholder="1"
                                                                    v-model="budget.amount" @keyup="calcBudget(index)"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                            <label>Valor Unitário:</label>
                                            <input-component :type-input="'text'" v-model="budget.unit_price"
                                                             @keyup="calcBudget(index)" v-money="money"
                                                             :maxlength="12"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                            <label>Valor Total:</label>
                                            <input-component :type-input="'text'" v-model="budget.total_price"
                                                             v-money="money" disabled/>
                                        </row-col-component>
                                    </row-component>
                                </form>
                            </div>

                            <h4>Total: R$ {{ totalResult }}</h4>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-one class="w-100" style="width: 100%;" @submit="submit" :icon="'plus circle'"
                            :name-button="'Adicionar'" :class="{ 'preloderSend': sendForm }"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>

</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputNumberComponent from "@/components/form/InputNumberComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {ACTION_MESSAGES} from "@/configs/constants";
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import ButtonRemoveRound from "@/components/buttons/ButtonRemoveRound";

const toast = useToast();
export default {
    name: "BudgetCreate",
    components: {
        ButtonRemoveRound,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        InputNumberComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Orçamento')
        this.$store.dispatch('loadProviderSelect')
        this.$store.dispatch('loadBudgetTemplateSelect')
        this.$store.dispatch('loadClientListSelect')
        this.checkACL()
        this.loadCloneItens()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itensTwo: {
                route: "/budget/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                budget_template_id: '',
                provider_id: '',
                provider_name: '',
                client_id: '',
                client_name: '',
                responsible: '',
                email: '',
                phone: '',
                show_provider: 1,
                valid: 15,
                obs: '',
                itens: []
            },

            client: '',
            provider: '',

            totalResult: 0,

            modal_client: false,
            modal_provider: false,
            modal_phone: false,
            modal_email: false,
            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeBudget', this.formDate)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                        this.$router.push({name: 'budget-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        openClient() {
            this.modal_client = !this.modal_client
        },

        closeClient(id) {
            this.modal_client = !this.modal_client
            this.getClient(id)
        },

        openProvider() {
            this.modal_provider = !this.modal_provider
        },

        closeProvider(id) {
            this.modal_provider = !this.modal_provider
            this.getProvider(id)
        },

        openEmail() {
            this.modal_email = !this.modal_email
        },

        openPhone() {
            this.modal_phone = !this.modal_phone
        },

        scrollTop() {
            window.scrollTo(0, 0)
        },

        addBudget() {
            this.formDate.itens.push({
                name: '',
                amount: '',
                unit_price: '',
                total_price: ''
            })
        },

        budgetRemove(index) {
            this.formDate.itens.splice(index, 1)
        },

        getClient(id) {
            this.$store.dispatch('loadClient', id).then((r) => {
                this.formDate.client_id = r.id
                this.formDate.client_name = r.surname
                this.$store.dispatch('loadEmailClient', r.id)
                this.$store.dispatch('loadPhoneClient', r.id)
            })
        },

        getProvider(id) {
            this.$store.dispatch('loadProvider', id).then((r) => {
                this.formDate.provider_id = r.id
                this.formDate.provider_name = r.surname
            })
        },

        getBudgetTemplate(id) {
            this.$store.dispatch('loadBudgetTemplateInfos', id).then((r) => {
                this.formDate.name = r.name
                this.formDate.itens = r.itens
                this.formDate.obs = r.obs
                this.formDate.provider_name = r.provider_name
                this.calcTotal()
            })
        },

        calcBudget(index) {
            let budget = this.formDate.itens[index];
            budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
            let value = budget.unit_price.replace(/[.,]/g, '')
            let amount = budget.amount
            let result = (value * amount / 100)
            budget.total_price = result.toLocaleString('pt-br', {minimumFractionDigits: 2})
            this.calcTotal()
        },

        calcTotal() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formDate.itens.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 25)
                .then((r) => {
                    if (r.data.indexOf('budget-create') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadCloneItens() {
            if (this.storeState.length > 0) {
                if (this.storeState.length > 1)
                    this.storeState.splice(0, 1)
                this.formDate = this.storeState[0]
                this.calcTotal();
            }
        },
    },

    // Clear storeState
    beforeMount() {
        this.storeState.splice(0, 1)
    },

    mounted() {
        if (!this.formDate.itens) {
            this.formDate.itens = [];
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        selectProvider() {
            return this.$store.state.var.selectProvider
        },

        selectBudgetTemplate() {
            return this.$store.state.var.selectBudgetTemplate
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        budgetAdd() {
            return this.formDate.itens;
        },

        storeState() {
            return this.$store.state.var.storeState
        },
    },
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.modal-budget {
    top: 50%;
    left: 50%;
    width: 40%;
    z-index: 9999;
    position: fixed;
    min-height: auto;
    background: white;
    padding: 40px 20px;
    overflow-y: scroll;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.btn-close {
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 10px 20px;
    position: absolute;
}

.my_btn {
    color: white;
    cursor: pointer;
    position: relative;
    background: #d67c1c;
    padding-bottom: 15px;
    border: 1px solid #d67c1c;
}

.border-content {
    margin-top: 20px;
    position: relative;
    background: #ebebeb;
    padding: 10px 20px 40px 20px;
}

h4 {
    color: #000;
    font-size: 16px;
    text-align: end;
    padding: 10px 15px;
    background-color: #c9c9c9;
}

@media (max-width: 991px) {
    .modal-budget {
        width: 95%;
    }
}
</style>
