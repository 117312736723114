<template>
  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError" :class="'mb-5'"></error-component>

    <div class="aux mb-4">
      <div class="avatar-form">
        <img v-if="me.photo" :src="imagePreview" class="avatar__image" />
        <img v-else src="@/assets/img/avatar.png" alt="" class="avatar__image" />
      </div>
      <p>{{ me.fullName }}</p>
    </div>

    <loder-send-component :text-title="'Carregando'" v-if="showLoder"></loder-send-component>

    <form autocomplete="off" v-else>
      <row-component>
        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
          <label>Foto:</label>
          <input type="file" name="file" id="formFile" @change="onFileChange" />
          <label for="formFile" class="labelFile">
            <span>{{ this.namePhoto }}</span>
            <span><i class="icon photo"></i></span>
          </label>
        </row-col-component>
      </row-component>

      <button-one v-if="sendForm" :icon="'cloud upload'" :name-button="'Enviando'" :class="{ preloderSend: sendForm }">
      </button-one>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
          <button-float :itens="itensTwo"></button-float>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import ButtonFloat from '@/components/buttons/ButtonFloat';
import ButtonOne from '@/components/buttons/ButtonOne.vue';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import LoderSendComponent from '@/components/LoderSendComponent';
import { useToast } from 'vue-toastification';
import { ACTION_MESSAGES } from '@/configs/constants';

const toast = useToast();

export default {
  name: 'UserUpdatePhoto',

  data() {
    return {
      imagePreview: '',

      itensOne: {
        title: 'Alterar Foto',
        icon: 'edit'
      },

      itensTwo: {
        route: '/',
        icon: 'reply all',
        name: 'Voltar'
      },

      formDate: {
        photo: ''
      },

      namePhoto: '',

      errors: [],
      showError: false,
      loaddingPageGroup: false,
      sendForm: false,
      showLoder: false,
      updatePassword: false
    };
  },
  methods: {
    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateUserPhoto', this.formDate)
          .then(r => {
            this.showError = false;
            this.sendForm = false;
            this.$store.dispatch('UpdateLinkPhoto', r.data[0].photo);
            toast.success(ACTION_MESSAGES.update_photo, { timeout: 2000 });
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
          });
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.formDate.photo = files[0];
      this.namePhoto = this.formDate.photo.name;
      if (files[0].type.indexOf('image') == 0) this.previewImage(files[0]);
      this.submit();
    },
    previewImage(file) {
      let reader = new FileReader();
      reader.onload = e => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    photoPreviewTimer() {
      this.imagePreview = this.me.photo;
    }
  },

  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    ButtonFloat,
    ButtonOne,
    ErrorComponent,
    LoderSendComponent
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    }
  },

  beforeMount() {
    setTimeout(() => this.photoPreviewTimer(), 1000);
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Usuário');
  }
};
</script>

<style src="../../../src/assets/css/avatar.css"></style>
