<template>
    <painel-component :itens="itenstwo" v-if="checkPermission('home-bar-one')">

        <row-component class="justify-content-end">
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Selecionar ano:</label>
                <Multiselect v-model="currentYear" :options="selectYear" @select="multiLoad(currentYear)"/>
            </row-col-component>
        </row-component>

        <BarChart :height="barChartMobile" :chartData="chartDataPreview"></BarChart>
    </painel-component>

    <painel-component :itens="itensOne" v-if="checkPermission('home-list-task')">
        <BarChart :height="barChartMobile" :chartData="chartDataEffective"></BarChart>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import Multiselect from "@vueform/multiselect";
import BarChart from "@/components/chart-js/BarChart";
import {useToast} from "vue-toastification";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";

const toast = useToast();

export default {
    name: "HomeView",
    components: {
        RowColComponent,
        RowComponent,
        PainelComponent,
        BarChart,
        Multiselect
    },

    created() {
        this.checkACL()
        this.loadHome(this.currentYear)
        this.dynamicTitle()
        this.$store.dispatch('updateNamePage', 'Home')
    },

    data() {
        return {
            barChartMobile: 100,

            itensOne: {
                title: "",
                icon: "chart bar outline"
            },
            itenstwo: {
                title: "",
                icon: "chart bar outline"
            },

            showListHome: false,
            showBarHome: false,
            permissionsPage: [],

            yearSelect: [],
            currentYear: this.getYear(),

            chartDataPreview: {
                labels: [],
                datasets: []
            },

            chartDataEffective: {
                labels: [],
                datasets: []
            },

            chartEntrancePreview: {
                label: 'Entrada',
                data: [],
                backgroundColor: ['rgba(22, 171, 57, 0.3)'],
                borderColor: ['rgba(22, 171, 57)'],
                borderWidth: 1
            },

            chartExitPreview: {
                label: 'Saída',
                data: [],
                backgroundColor: ['rgba(219, 40, 40, 0.3)'],
                borderColor: ['rgba(219, 40, 40)'],
                borderWidth: 1
            },


          chartAnticipatePreview: {
            label: 'Antecipado',
            data: [],
            backgroundColor: ['rgba(255, 165, 0, 0.3)'],
            borderColor: ['rgba(255, 165, 0)'],
            borderWidth: 1
          },


            chartEntranceEffective: {
                label: 'Entrada',
                data: [],
                backgroundColor: ['rgba(22, 171, 57, 0.3)'],
                borderColor: ['rgba(22, 171, 57)'],
                borderWidth: 1
            },

            chartExitEffective: {
                label: 'Saída',
                data: [],
                backgroundColor: ['rgba(219, 40, 40, 0.3)'],
                borderColor: ['rgba(219, 40, 40)'],
                borderWidth: 1
            },

          chartAnticipateEffective: {
            label: 'Antecipado',
            data: [],
            backgroundColor: ['rgba(255, 165, 0, 0.3)'],
            borderColor: ['rgba(255, 165, 0)'],
            borderWidth: 1
          },

        }
    },

    methods: {
        loadHome(year) {
            this.$store.dispatch('loadHome', year)
                .then((r) => {
                    let preview = r.cashFlowForecast

                    this.chartDataPreview.labels = preview.labels
                    this.chartEntrancePreview.data = preview.entrance
                    this.chartExitPreview.data = preview.exit
                    this.chartAnticipatePreview.data = preview.anticipate
                    this.chartDataPreview.datasets = [this.chartEntrancePreview, this.chartExitPreview,this.chartAnticipatePreview]

                    let effective = r.cashFlowEffective
                    this.chartDataEffective.labels = effective.labels
                    this.chartEntranceEffective.data = effective.entrance
                    this.chartExitEffective.data = effective.exit
                    this.chartAnticipateEffective.data = effective.anticipate
                    this.chartDataEffective.datasets = [this.chartEntranceEffective, this.chartExitEffective, this.chartAnticipateEffective]

                    this.yearSelect = r.yearSelect
                }).catch((error) => {
                toast.error("Erro desconhecido", {timeout: 1200})
                if (error.response.status === 401 || error.response.status === 429) {
                    setTimeout(() => {
                        location.reload()
                    }, 500)
                    this.$router.push({name: 'login'})
                }
            })
        },

        multiLoad(year){
            this.loadHome(year)
        },

        dynamicTitle() {
            let data = new Date()
            let year = data.getFullYear()

            this.itensOne.title = `Fluxo de caixa ${year} (Efetivado)`
            this.itenstwo.title = `Fluxo de caixa ${year} (Previsão)`
        },

        getYear() {
            let date = new Date()
            return date.getFullYear()
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    this.permissionsPage = r.data;
                })
        },

        checkPermission(page) {
            return this.permissionsPage.indexOf(page) !== -1;
        }

    },

    computed: {
        selectYear() {
            return this.yearSelect
        }
    },

    beforeUpdate() {
        if (window.innerWidth <= 991) {
            this.barChartMobile = 300
        }
    }

}
</script>
