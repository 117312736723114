import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'entrance-and-exit-preview';
export default {
  loadEntranceExitPreviews(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, {
          params
        })
        .then(response => {
          context.commit('LOAD_ENTRANCE_EXIT_PREVIEW', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        });
    });
  },

  storeEntranceExitPreview(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {});
    });
  },

  loadEntranceExitPreview(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateEntranceExitPreview(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  destroyEntranceExitPreview(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  storeLowEntranceExitPreview(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}entrance-and-exit-preview-low/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  }
};
