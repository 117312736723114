import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'user';
export default {
  loadUsers(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_USERS', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        });
    });
  },

  storeUser(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {});
    });
  },

  loadUser(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e));
    });
  },

  updateUser(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {});
    });
  },

  destroyUser(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateUserPassword(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}userpassword`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {});
    });
  },

  updateUserPhoto(context, formData) {
    let config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}userphoto`, formData, config)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {});
    });
  }
};
