<template>
  <!-- Start menuContainer -->
  <div class="menuContainer" :class="{ openMenuContainer: openMenu }">
    <!-- Start logoContainer -->
    <div class="logoContainer">
      <router-link to="/" @click="activeLinkRouteRemove">
        <img src="@/assets/img/logo.png" alt="D7M marketing" />
      </router-link>
    </div>
    <!-- End logoContainer -->

    <!-- Start userContainer -->
    <div class="userContainer" @click="dropMenu.navOne = !dropMenu.navOne">
      <div class="avatar">
        <img v-if="me.photo" :src="me.photo" />
        <img v-else src="@/assets/img/avatar.png" />
        <p>{{ me.name }} <i :class="dropMenu.navOne ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i></p>
      </div>
      <transition name="slide">
        <ul class="links submenu userMenu" v-if="dropMenu.navOne">
          <li>
            <router-link :to="{ name: 'user-update-photo' }"> <i class="icon image"></i> Alterar Foto </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user-update-password' }">
              <i class="icon unlock alternate"></i>Alterar Senha
            </router-link>
          </li>
          <li>
            <a href="#" @click.prevent="logout"><i class="icon sign-out"></i> Sair</a>
          </li>
        </ul>
      </transition>
    </div>
    <!-- End userContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageCadastro">
      <ul>
        <li @click="dropMenu.navTwo = !dropMenu.navTwo">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuCadastro.icon"></i> {{ linkMenu.menuCadastro.name }}
            <i :class="dropMenu.navTwo ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navTwo">
            <div v-for="(link, index) in linkMenu.menuCadastro.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageContrato">
      <ul>
        <li @click="dropMenu.navContrato = !dropMenu.navContrato">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuContrato.icon"></i> {{ linkMenu.menuContrato.name }}
            <i :class="dropMenu.navContrato ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navContrato">
            <div v-for="(link, index) in linkMenu.menuContrato.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageEstoque">
      <ul>
        <li @click="dropMenu.navEstoque = !dropMenu.navEstoque">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuEstoque.icon"></i> {{ linkMenu.menuEstoque.name }}
            <i :class="dropMenu.navEstoque ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navEstoque">
            <div v-for="(link, index) in linkMenu.menuEstoque.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageFinanceiro">
      <ul>
        <li @click="dropMenu.navFinanceiro = !dropMenu.navFinanceiro">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuFinanceiro.icon"></i> {{ linkMenu.menuFinanceiro.name }}
            <i :class="dropMenu.navFinanceiro ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navFinanceiro">
            <div v-for="(link, index) in linkMenu.menuFinanceiro.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageManutencao">
      <ul>
        <li @click="dropMenu.navManutencao = !dropMenu.navManutencao">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuManutencao.icon"></i> {{ linkMenu.menuManutencao.name }}
            <i :class="dropMenu.navManutencao ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navManutencao">
            <div v-for="(link, index) in linkMenu.menuManutencao.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pagePlanejamento">
      <ul>
        <li @click="dropMenu.navPlanejamento = !dropMenu.navPlanejamento">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuPlanejamento.icon"></i> {{ linkMenu.menuPlanejamento.name }}
            <i :class="dropMenu.navPlanejamento ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navPlanejamento">
            <div v-for="(link, index) in linkMenu.menuPlanejamento.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageProposta">
      <ul>
        <li @click="dropMenu.navProposta = !dropMenu.navProposta">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuProposta.icon"></i> {{ linkMenu.menuProposta.name }}
            <i :class="dropMenu.navProposta ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navProposta">
            <div v-for="(link, index) in linkMenu.menuProposta.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageSite">
      <ul>
        <li @click="dropMenu.navSite = !dropMenu.navSite">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuSite.icon"></i> {{ linkMenu.menuSite.name }}
            <i :class="dropMenu.navSite ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navSite">
            <div v-for="(link, index) in linkMenu.menuSite.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->

    <!-- Start LinksContainer -->
    <div class="linkContainer" v-if="showItensMenu.pageReport">
      <ul>
        <li @click="dropMenu.navReport = !dropMenu.navReport">
          <a href="#"
            ><i :class="'icon ' + linkMenu.menuReport.icon"></i> {{ linkMenu.menuReport.name }}
            <i :class="dropMenu.navReport ? 'icon caret down iconMenu' : 'icon caret right iconMenu'"></i
          ></a>
        </li>
        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navReport">
            <div v-for="(link, index) in linkMenu.menuReport.subLinks" :key="index" @click="$emit('menuResp')">
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                :class="[{ activeBackground: activeLinkRoute(link.route) }, 'linkroute']"
                >{{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
    <!-- End LinksContainer -->
  </div>
  <!-- End menuContainer -->
</template>

<script>
import linkMenu from '@/configs/linksMenu';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'NavMenu',

  data() {
    return {
      dropMenu: {
        navOne: false,
        navTwo: false,
        navPlanejamento: false,
        navFinanceiro: false,
        navProposta: false,
        navEstoque: false,
        navContrato: false,
        navSite: false,
        navManutencao: false,
        navReport: false
      },
      linkMenu,
      permissionsPage: [],
      activeLink: false,
      showItensMenu: {
        pageCadastro: false,
        pagePlanejamento: false,
        pageFinanceiro: false,
        pageLinkGroup: false,
        pageProposta: false,
        pageEstoque: false,
        pageContrato: false,
        pageSite: false,
        pageManutencao: false,
        pageReport: false
      }
    };
  },

  created() {
    this.checkACLnav();
  },

  emits: ['menuResp'],

  methods: {
    dropIt() {
      this.isDropped = !this.isDropped;
    },

    logout() {
      swal({
        title: 'Atenção!',
        text: `Deseja relamente sair do sistema ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store.dispatch('logout');
          toast.success('Logout efetuado com sucesso!', { timeout: 2000 });
          setTimeout(() => this.$router.push({ name: 'login' }), 2200);
        }
      });
    },

    async checkACLnav() {
      this.$store.dispatch('ACLitensnav').then(r => {
        this.permissionsPage = r.data;
        this.permissionsPage.forEach(item => {
          if (linkMenu.menuCadastro.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageCadastro = true;
          }
          if (linkMenu.menuPlanejamento.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pagePlanejamento = true;
          }

          if (linkMenu.menuFinanceiro.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageFinanceiro = true;
          }

          if (linkMenu.menuProposta.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageProposta = true;
          }

          if (linkMenu.menuEstoque.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageEstoque = true;
          }

          if (linkMenu.menuContrato.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageContrato = true;
          }

          if (linkMenu.menuSite.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageSite = true;
          }

          if (linkMenu.menuManutencao.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageManutencao = true;
          }

          if (linkMenu.menuReport.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageReport = true;
          }

          /* if (linkMenu.menuCadastro.pageLinksNavCheck.indexOf('item') == item) {
               this.showItensMenu.pageCadastro = true
             }*/
        });
      });
    },

    checkPermissionMenu(page) {
      let shouldSkip = false;
      this.permissionsPage.forEach(value => {
        if (shouldSkip) {
          return;
        }

        if (page.includes(value)) {
          shouldSkip = true;
          return;
        }
      });

      if (shouldSkip) return true;
      return false;

      /* this.pageMenuShow = false;
      this.permissionsPage.every(value => {
        if (page.includes(value)){
        }

      });
      if (this.pageMenuShow)
        return true;
      return false;*/
    },

    activeLinkRoute(nameLink) {
      let x1 = nameLink.split('/');
      if (this.$route.matched[0].path == '/' + x1[1]) return true;
      return false;
    },

    activeLinkRouteRemove() {
      this.activeLink = false;
      // some code to filter users
    }
  },

  props: {
    openMenu: {
      required: true,
      type: Boolean
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    currentRouteNamepath() {
      return this.$route.path;
    }
  }

  /* beforeMount() {
   }*/

  // beforeUpdate() {
  //   if(this.openMenu){
  //   }
  // }
};
</script>

<style scoped lang="scss">
.userMenu {
  margin-top: 15px;
}

.menuContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  min-height: 100vh;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  padding: 0 10px;
  overflow-x: auto;
}

.menuContainer .logoContainer {
  border-bottom: 1px inset #c3c3c3;
  padding: 10px 0;
}

.menuContainer .logoContainer img {
  max-width: 110px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.menuContainer .userContainer {
  border-bottom: 1px solid #c3c3c3;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 48px;
}

.menuContainer .userContainer:hover {
  cursor: pointer;
}

.menuContainer .userContainer .avatar img {
  max-width: 35px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

.menuContainer .userContainer p {
  color: #000;
  margin: 0;
  padding-top: 5px;
}

.menuContainer .submenu {
  clear: both;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
  background-color: #e3e3e3;
  width: 100%;
}

.menuContainer .submenu li {
  padding: 5px 0;
}

.menuContainer .submenu li a {
  font-size: 14px;
}

.menuContainer .links a {
  font-family: Lato;
  display: block;
  text-decoration: none;
  color: #000;
  line-height: 1.5em;
  height: 30px;
}

.menuContainer .links a i {
  color: #686666;
  font-size: 15px;
}

.menuContainer .iconMenu {
  position: absolute;
  right: 15px;
}

.linkroute {
  display: block;
  font-family: Lato;
  padding: 10px;
  height: 45px;
  background-color: #ebebeb;
  text-decoration: none;
  color: #000000;
}

.linkroute:hover {
  background-color: rgba(243, 143, 12, 1) !important;
  color: #ffffff;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.linkContainer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
}

.linkContainer ul:hover {
  cursor: pointer;
}

.linkContainer ul li {
  padding: 10px;
  height: 45px;
  background-color: #ebebeb;
}

.linkContainer ul li a {
  font-family: Lato;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
}

.linkContainer ul li img {
  margin: 0 0.25rem 0.2rem 0;
  height: 1em;
  text-align: center;
}

.linkContainer .iconMenu {
  position: absolute;
  right: 15px;
}

.linkContainer .submenu {
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
  width: 100%;
}

.activeBackground {
  background-color: rgba(243, 143, 12, 1) !important;
  color: white !important;
}

.dropMenu-enter,
.dropMenu-leave-to,
.dropMenu-enter-from {
  transform: scaleY(0);
}

.slide-enter,
.slide-leave-to,
.slide-enter-from {
  transform: scaleY(0);
}

@media (max-width: 991px) {
  .menuContainer {
    left: -260px;
    transition: left 0.5s ease-in;
  }

  .openMenuContainer {
    left: 0px;
    transition: left 0.5s ease-in;
  }
}
</style>
